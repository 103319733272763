import React from "react"
import { TransitionGroup, Transition as ReactTransition } from "react-transition-group"

const timeoutExit = 0
const timeoutEnter = 300
const getTransitionStyles = {
  entering: {
    /* position: `absolute`, */
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeoutEnter}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeoutExit}ms ease-in-out`,
    opacity: .5,
  },
}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeoutEnter,
            exit: timeoutExit,
          }}
        >
          {status => (
            <div
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition