import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

const Footer = () => {
	const data = useStaticQuery(graphql`
		query {
			allFooterLinksJson {
				edges {
					node {
						alt
						path
						icon {
							childImageSharp {
								original {
									src
								}
							}
						}
					}
				}
			}
		}
	`);
	return (
		<footer className="footer">
			<div className="container">
				<div className="footerItems">
					<div className="footerIcons">
						{data.allFooterLinksJson.edges.map((item, index) => {
							return (
								<div key={index} >
									<a
										href={item.node.path}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											className="iconImage"
											src={item.node.icon.childImageSharp.original.src}
											alt={item.node.alt}
										/>
									</a>
								</div>
							);
						})}
					</div>
					<div className="footerText">
						<span className="footerTextWhite">
							<Link to="/privacy" aria-label="This is Cookie Policy & Privacy Policy Page">Privacy Policy</Link>
						</span>
						<span className="footerTextGray">
							All Rights Reserved | Tribe Studios, 2021
						</span>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
