import React from "react"
import { HelmetProvider } from 'react-helmet-async';
import PropTypes from "prop-types";
import Transition from "../../pageTransition/Transition";
import Footer from "./Footer";
import "../../styles/styles.css";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

const Layout = ({ children, location }) => {
  return (
    <HelmetProvider defer={true}>
        <div className="mainContainer">
          <Transition location={location}>
            <main>{children}
            <CookieConsent
						location="bottom"
						buttonText="I understand"
						expires={150}
					>
						This website uses cookies to enhance the user experience.
						<Link to="/privacy">Read More<span className="descriptiveTextForSeo">COOKIE POLICY</span></Link>
					</CookieConsent>
            </main>
            <Footer />
          </Transition>
        </div>
    </HelmetProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout

